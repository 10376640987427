// export const cgiConfirmModalSchema = {
//   $schema: "https://json-schema.org/draft/2019-09/schema",
//   $id: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json",
//   type: "object",
//   properties: {
//     attributes: {
//       type: "object",
//       title: "Attributes",
//       properties: {
//         end: { type: "string", title: "Letzter Tag aktuelle Kraft", format: "date" },
//         start: { type: "string", title: "Erster Tag Wechselkraft", format: "date" },
//       }
//     }
//   }
// }

export const cgiConfirmModalSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json",
  type: "object",
  properties: {
    currentAgEnd: { type: "string", title: "Letzter Rechnungstag aktuelle Kraft", format: "date" },
    currentCgiDeparture: { type: "string", title: "Abreise aktuelle Kraft", format: "date" },
    billStart: { type: "string", title: "Erster Rechnungstag", format: "date" },
    billEnd: { type: "string", title: "Letzter Rechnungstag", format: "date" },
    arrival: { type: "string", title: "Anreise", format: "date" },
    departure: { type: "string", title: "Abreise", format: "date" },
  }
}
export const uiCgiConfirmModalSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    arrival: {
      "ui:label": "Anreisedatum",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/arrival"
    },
    billStart: {
      "ui:label": "Erster Rechnungstag",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/billStart"
    },
    departure: {
      "ui:label": "Abreisedatum (nächster Wechsel)",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/departure"
    },
    billEnd: {
      "ui:label": "Letzter Rechnungstag",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/billEnd"
    },
  }
}

export const uiCgiSwapConfirmModalSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/cgi-swap-confirm-modal-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    currentAgEnd: {
      "ui:label": "Letzter Rechnungstag aktuelle Kraft",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/currentAgEnd"
    },
    currentCgiDeparture: {
      "ui:label": "Abreisedatum aktuelle Kraft",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/currentCgiDeparture"
    },
    agStart: {
      "ui:label": "Erster Rechnungstag Wechselkraft",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/agStart"
    },
    cgiArrival: {
      "ui:label": "Anreisedatum Wechselkraft",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/cgiArrival"
    },
    cgiDeparture: {
      "ui:label": "Abreisedatum Wechselkraft (Voraussichtlich)",
      "ui:widget": "b-input",
      "ui:options": {
        type: "date",
        // zone: "Europe/Berlin"
        // 'minute-step': 5
      },
      "ui:classNames": ["col-6"],
      $ref: "https://crm.pflegehilfe-senioren.de/cgi-confirm-modal.json#/properties/cgiDeparture"
    },
  }
}