
  import { defineComponent, ref, watch, computed, reactive, getCurrentInstance } from "vue";
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";;
  import { ResourceObject } from '@/models/jsonapi'
  import { cgiConfirmModalSchema, uiCgiConfirmModalSchema } from "@/view_scripts/form_schemas/modals";
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  export default defineComponent({
    components: {
      SchemaForm
    },
    props: {
      visible: {
        type: Boolean,
        default: true
      },
      suggestionData: {
        type: Object,
        required: true
      },
      confirm:  {
        type: Boolean,
        required: true
      }
    },
    setup(props: any, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const modalVisible = ref(props.visible)
      const rejectReasonSelectorPlaceholder = {value: null, text: 'Grund für den Abbruch wählen'}
      watch(() => props.visible, (newValue) => {
        modalVisible.value = newValue
        // ticketMessage.value = ''
      }, { immediate: true })

      let confirmData
      if (props.confirm) {
        const cs = props.suggestionData.meta.tableRow.careStay
        confirmData = reactive<{[key: string]: any}>({
          wrongstate: false,
          careStayId: cs.id,
          billStart: cs.attributes.billStart.split('T')[0],
          billEnd: cs.attributes.billEnd.split('T')[0],
          arrival: cs.attributes.arrival.split('T')[0],
          departure: cs.attributes.departure.split('T')[0],
        })
      } else {
        confirmData = reactive<{[key: string]: any}>({
          wrongstate: false,
          careStayId: cs.id,
          selectedReason: null,
          rejectReasons: null,
          customReason: null,
        })
        if (['Abgelehnt', 'Abgesagt', 'Abgebrochen'].includes(props.suggestionData.attributes.stage)) {
          confirmData.wrongstate = true
          // prompt(root, 'Nur Vorgestellte BKs können abgelehnt werden.')
        }
        const listQuery = {'filter[sys_id]': 'suggestion_cancel_reasons',include: 'sublists'}
        showLoading.value = true
        root.$store.dispatch('lists/load', listQuery).then((r) => {
          confirmData.rejectReasons = r.included.map((rr)=>{
            return {value: rr.id, text: rr.attributes?.name}
          }).concat({value: 'new', text: "Eigenen Grund angeben..."})
          confirmData.rejectReasons.unshift(rejectReasonSelectorPlaceholder)
        }).finally(() => {showLoading.value = false})
      }

      const cgiConfirm = async () => {
        const data = {
          attributes: {
            billStart: confirmData.billStart,
            billEnd: confirmData.billEnd,
            arrival: confirmData.arrival,
            departure: confirmData.departure,
          },
          usecase: 'cs_confirm',
        }
        showLoading.value = true
        try {
          await root.$store.dispatch('care_stays/edit', { id: confirmData.careStayId, body: data })
          // await root.$store.dispatch('suggestions/edit', { id: confirmData.suggestionId, body: data })
        } finally {
          emit('done', true)
          showLoading.value = false
        }
      }

      const cgiReject = () => {
        if (confirmData.wrongstate) return
        const data = {
          usecase: 'cgi_cancel',
          list_id: confirmData.selectedReason,
          custom_reason: confirmData.customReason
        }
        if (confirmData.suggestionId){
          showLoading.value = true
          root.$store.dispatch('suggestions/edit', { id: confirmData.suggestionId, body: data })
            .then((r) => {
              emit('done', true)
              showLoading.value = false
            })
        }
      }

      const confirmDataUpdated = (formValue) => {
        Object.entries(formValue).forEach(([key, val], idx) => {
          // console.log('key: ', key,'val: ', val,'idx: ', idx)
          confirmData[key] = val
        })
      }

      const modalClose = () => {
        emit('done', false)
      }

      return {
        showLoading,
        modalVisible,
        confirmData,
        modalClose,
        cgiConfirm,
        cgiReject,
        confirmDataUpdated,
        cgiConfirmModalSchema,
        uiCgiConfirmModalSchema
      }
    }
  })
